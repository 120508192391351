import React from 'react'
import { CustomContainer as Container, Dialog } from '../../components'
import aboutImage from '../../assets/images/about.png'
import style from './about.module.css'
import { AiFillRightCircle } from 'react-icons/ai'
function About() {
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <section id="about-us" style={{ marginTop: "105px", backgroundColor: "#F7F7F7", paddingTop: '95px' }}>
            <Container>
                <div className={style.content}>
                    <div>
                        <img src={aboutImage} alt="smoothie factory" />
                    </div>
                    <div className={style.desc}>
                        <h3>About us</h3>
                        <p>We enjoy sharing our energy and passion for the brand and innovative and unique ‘Healthier Side of Life’ product offerings. Our ‘Yes We Can’ attitude has taken us far and thanks to our hard work and help from extraordinary franchise partners, Smoothie Factory operates in more international markets that any other juice bar brand.</p>
                        <div className={style.link} onClick={handleClickOpenDialog}>
                            <span>Download the app now</span>
                            <AiFillRightCircle />
                        </div>
                    </div>
                </div>
            </Container>
            <Dialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </section>
    )
}

export default About