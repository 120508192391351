import React from 'react'
import style from './downloadBtns.module.css'

import GooglePlayIcon from '../../assets/icons/google-play.svg'
import APPLE_ICON from '../../assets/icons/apple/icons8-apple-logo-250.svg'
import { playStore, appStore } from '../../data/applink'


function DownloadBtns() {
    let appStoreLink = appStore
    let playStoreLink = playStore
    const handleLink = (link) => {
        window.open(link, "_blanc")
    }
    return (
        <div className={`${style.btn_parent}`}>

            <div className={style.btn}>{/* download btn for apple store  */}
                <div className={`${style.btn_icon} ${style.APPLE_ICON}`}>
                    <img src={process.env.PUBLIC_URL + APPLE_ICON} alt="appe icon" />
                </div>
                <div className={style.btn_text} onClick={(e) => { handleLink(appStoreLink) }}>
                    <p>Download on the</p>
                    <p>App store</p>
                </div>
            </div>

            <div className={style.btn}>{/* download btn for google play  */}
                <div className={`${style.btn_icon} ${style.GooglePlayIcon}`}>
                    <img src={process.env.PUBLIC_URL + GooglePlayIcon} alt="google play icon" />
                </div>
                <div className={style.btn_text} onClick={(e) => { handleLink(playStoreLink) }} >
                    <p>Get it on</p>
                    <p>Google Play</p>
                </div>
            </div>

        </div>
    )
}

export default DownloadBtns