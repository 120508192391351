import { createBrowserRouter } from "react-router-dom"
import { NotFound, Home } from "../pages"
import { NavbarLayout } from '../layouts'

export const router = createBrowserRouter([
    {
        path: '/',
        exact: true,
        element: <NavbarLayout />,
        children: [
            {
                path: '/',
                exact: true,
                element: <Home />
            }
        ]

    },
    {
        path: "*",
        element: <NotFound />
    }
])