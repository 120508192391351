import AxiosInstance from "./AxiosInstance";

class ContactServices {
  sendSupportMessage = async (data) => {
    const resp = await AxiosInstance.post("contact/add", data);
    return resp;
  };
  dataValidation = (data) => {
    if (data.fullname.toString().length < 2) {
      return "Name Too short";
    }
    if (data.fullname.toString().length > 75) {
      return "Name Too Length";
    }
    if (data.message.toString().length < 2) {
      return "Name Too short";
    }
    return true;
  };
}

export default ContactServices;
