import React from 'react'
import style from './textTitle.module.css'
function TextTitle({ text }) {
    return (
        <section className={style.text_title}>
            <div className={style.text_title_container}>
                <h3 className={style.text}>{text}</h3>
            </div>
        </section>
    )
}

export default TextTitle