import React, { useEffect, useRef } from 'react'
import style from './customcontainer.module.css'

function CustomContainer({ children, className, mt, mb, mr, ml, ...prop }) {
    const containerChild = useRef(null)
    useEffect(() => {
        if (mt) {
            containerChild.current.style.marginTop = mt
        }
        if (mb) {
            containerChild.current.style.marginBottom = mb
        }
        if (mr) {
            containerChild.current.style.marginRight = mr
        }
        if (ml) {
            containerChild.current.style.marginLeft = ml
        }
    }, [mt, mb, mr, ml])
    return (
        <div className={`${style.container}`}>
            <div ref={containerChild} className={style.containerChild}>
                {children}
            </div>
        </div>
    )
}

export default CustomContainer