import axios from "axios"
import {APP_API_URL_Local,APP_API_URL_PROD} from '../config/index'


export const baseUrl = () => {
    switch (process.env.NODE_ENV) {
        case "development":
            return APP_API_URL_Local
        default:
            return APP_API_URL_PROD
    }
}

const AxiosInstance = axios.create({
    baseURL:baseUrl()+'api/',
    headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
  timeoutErrorMessage:"Please check your connection"
})

export default AxiosInstance