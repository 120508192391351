import React from 'react'
import style from './smoothie.module.css'

function Smoothie({ items }) {
    return (
        <div className={style.smoothie} >
            <img src={process.env.PUBLIC_URL + items.path} alt={items.title} />
            <h4>{items.title}</h4>
            <p>{items.desc}</p>
        </div>
    )
}

export default Smoothie