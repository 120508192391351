import React from 'react'
import { Outlet } from 'react-router-dom'
import { Topbar, Footer } from '../components'

function NavbarLayout() {
    return (
        <>
            <Topbar />
            <Outlet />
            <Footer />
        </>
    )
}

export default NavbarLayout