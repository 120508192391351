import React from 'react'
import style from './ourSmoothie.module.css'
import data from './../../../data/smoothies'
import Smoothie from './Smoothie/Smoothie'


function OurSmoothies() {
    return (
        <div className={style.our_smoothies}>
            <div className={style.our_smoothies_content}>
                {data.map((items, index) => {
                    return <Smoothie key={index} items={items} />
                })}
            </div>
        </div>
    )
}

export default OurSmoothies


