import React, { useEffect, useState } from 'react'
import Navbar from './Navbar/Navbar'
import logo from '../../assets/logo/logo.svg'
import { Link } from 'react-router-dom'
import { CustomContainer as Container, Dialog } from '../index'
import style from "./topbar.module.css"
import useMediaQuery from '@mui/material/useMediaQuery';
import { HiMenuAlt2 } from 'react-icons/hi'



function Topar() {

    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    const [showMenu, setShowMenu] = useState(false)
    const handleMenu = (e) => {
        setShowMenu(!showMenu)
    }
    const matches = useMediaQuery('(max-width:1000px)');
    useEffect(() => {
        if (!matches) {
            setShowMenu(false)
        }
    }, [matches])
    return (
        <>
            <div className={style.topbar}>
                <div className={style.content}>
                    <Container mr={'36px'} ml={'140px'} className='posRelative' >
                        <div className={style.content_child}>
                            <Link to='/' >
                                <img
                                    src={process.env.PUBLIC_URL + logo}
                                    alt="smoothie factory logo"
                                    className={style.logo}
                                />
                            </Link>
                            <div className={style.nav_containre}>
                                <Navbar showMenu={showMenu} matches={matches} handleClickOpenDialog={handleClickOpenDialog} />
                            </div>
                            {matches && <HiMenuAlt2 className={style.menu_icon} onClick={(e) => { handleMenu(e) }} />}
                        </div>
                    </Container>
                </div>
            </div>
            <Dialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </>
    )
}

export default Topar