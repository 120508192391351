import juicesIconPath from '../assets/icons/rewards/10.svg';
import capIconPath from '../assets/icons/rewards/birthday-cap.svg';
import trophyIconPath from '../assets/icons/rewards/trophy.svg';

const rewardsData = [
    {
        path: juicesIconPath,
        desc: "Grab your 10 smoothies with us and we'll treat you to the 11th one for free!"
    },
    {
        path: capIconPath,
        desc: "Is it your birthday? Treat yourself to your favorite drink for free by ordering through our mobile app!"
    },
    {
        path: trophyIconPath,
        desc: "Accumulate points with every purchase and redeem them for your favorite smoothie at no extra cost"
    },
]

export default rewardsData;