import React from 'react'
import style from './HomeImage.module.css'
import HomeImagePath from "../../../assets/images/home.png"

function HomeImage() {
    return (
        <section className={style.home_image}>
            <div className={style.home_image_container}>
                <img src={process.env.PUBLIC_URL + HomeImagePath} alt="home"
                    className={style.image} />
            </div>
        </section>
    )
}

export default HomeImage