import React from 'react'
import style from './reward.module.css'
function Reward({ item }) {
    return (
        <div className={style.reward}>
            <div>
                <img src={process.env.PUBLIC_URL + item.path} alt="" />
            </div>
            <div>
                <p>{item.desc}</p>
            </div>
        </div>
    )
}

export default Reward