import Fruit_IconPath from "../assets/icons/ourSmoothieIcons/fruit.svg"
import Season_IconPath from '../assets/icons/ourSmoothieIcons/Season.svg'
import Juice_Icon_path from '../assets/icons/ourSmoothieIcons/juice.svg'
import Pre_order_Icon_Path from '../assets/icons/ourSmoothieIcons/Pre_order.svg'


const smoothieData = [
    {
        path: Fruit_IconPath,
        title: '100 % real fruit and vegetables',
        desc: "Our Smoothies, Super-food Power Bowls, juices, toast, salads, soups and more, are all prepared with nutrient-rich whole fruit and vegetables."
    },
    {
        path: Season_IconPath,
        title: 'Follow the seasons',
        desc: "Our curated menu is designed to follow the seasons, offering a rotating selection of smoothies that are perfectly in tune with the freshest ingredients."
    },
    {
        path: Juice_Icon_path,
        title: 'Customize Smoothie',
        desc: "Every order is chance to design and customize a smoothie that’s uniquely you."
    },
    {
        path: Pre_order_Icon_Path,
        title: 'Pre-Order',
        desc: "Save time and skip the line by pre-ordering your smoothie through our convenient mobile app"
    },
]
export default smoothieData