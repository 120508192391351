import React from 'react'
import style from './home.module.css'
import { CustomContainer as Container, TextTitle } from '../../components'
import HomeText from './HomeText/HomeText'
import HomeImage from './HomeImage/HomeImage'
import OurSmoothies from './OurSmoothies/OurSmoothies'
import About from '../About/About'
import Rewards from '../Rewards/Rewards'
import Contact from '../Contact/Contact'


function Home() {
    return (
        <section id='home' className={style.home_section}>
            <Container ml={'140px'} mr={'36px'}>

                {/* section one */}
                <div className={style.content}>
                    <HomeText />
                    <HomeImage />
                </div>

                {/* section two */}
                <TextTitle text={"why smoothie factory app"} />
            </Container>
            <Container>
                {/* section three */}
                <div className={style.our_smoothie}>
                    <OurSmoothies />
                </div>
            </Container>
            <About />
            <Rewards />
            <Contact />
        </section>
    )
}

export default Home