import React, { useState } from "react";
import style from "./contact.module.css";
import { CustomContainer as Container } from "../../components";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ContactServices from "../../service/contact.service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initValues = {
  fullname: "",
  email: "",
  message: "",
};

function Contact() {
  const [form, setForem] = useState(initValues);
  const [alterValues, setAlertValues] = useState({
    message: "",
    type: "success",
  });

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForem({ ...form, [name]: value });
  };

  // open snackbar
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  // close snackbar with click
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const dataValidation = new ContactServices().dataValidation({ ...form });
      if (dataValidation === true) {
        const { status } = await new ContactServices().sendSupportMessage({
          ...form,
        });
        if (status !== 200) {
          throw new Error();
        }
        setForem(initValues);
        setAlertValues({
          message: "The message has been sent successfully!",
          type: "success",
        });
        handleClick();
      } else {
        setAlertValues({
          message: dataValidation,
          type: "error",
        });
        handleClick();
      }
    } catch (error) {
      setAlertValues({
        message: "Something wrong please try again later!",
        type: "error",
      });
      handleClick();
    }
  };

  return (
    <section
      id="contact-us"
      style={{
        marginTop: "105px",
        paddingTop: "95px",
        backgroundColor: "#F7F7F7",
      }}
    >
      <Container>
        <div className={style.content}>
          <div className={style.desc}>
            <h3>Have any questions? Please feel free to contact us</h3>
            <h5>Fill out the short form below to communicate with us</h5>
          </div>
          <div className={style.content_form}>
            <form
              className={style.form}
              onSubmit={(e) => {
                onSubmitHandler(e);
              }}
            >
              <label htmlFor="name">Full Name</label>
              <input
                id="name"
                type="text"
                placeholder="Enter full name"
                required
                name="fullname"
                value={form.fullname}
                onChange={onChangeHandler}
              />
              <label htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                placeholder="Enter email address"
                required
                name="email"
                value={form.email}
                onChange={onChangeHandler}
              />
              <label htmlFor="textarea">Message</label>
              <textarea
                id="textarea"
                placeholder="Enter a message"
                minLength={2}
                required
                value={form.message}
                name="message"
                onChange={onChangeHandler}
              ></textarea>
              <button type="submit">submit the message</button>
            </form>
          </div>
        </div>
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alterValues.type}
          sx={{ width: "100%" }}
        >
          {alterValues.message}
        </Alert>
      </Snackbar>
    </section>
  );
}

export default Contact;
