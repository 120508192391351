import React from 'react'
import MuiDialog from '@mui/material/Dialog';
import logo from './../../assets/logo/logo.svg'
import style from './dialog.module.css'
import CloseIcon from '@mui/icons-material/Close';
import { DownloadBtns } from '../index'
import locationIconPath from "../../assets/icons/location.svg"
import { Link } from 'react-router-dom'
const PrivacyAndPolicyLink = "https://www.privacypolicies.com/live/ec3dd02a-cd34-4cd2-9adc-4b742e729948"

function Dialog({ openDialog, handleCloseDialog }) {
    return (
        <MuiDialog className={style.dialog} open={openDialog} onClose={handleCloseDialog}>
            <div className={style.container}>
                <div className={style.content}>
                    <CloseIcon onClick={handleCloseDialog} className={style.close_btn}></CloseIcon>
                    <img src={process.env.PUBLIC_URL + logo} alt="smoothie factory" />
                    <DownloadBtns />
                    <div className={style.footer}>
                        <p>Conveniently pre-order your smoothie <br /> through our app at the following location:</p>
                        <div className={style.location}>
                            <img src={process.env.PUBLIC_URL + locationIconPath} alt="location icon" />
                            <span>BPC Gym</span>
                        </div>
                        <div className={style.location}>
                            <img src={process.env.PUBLIC_URL + locationIconPath} alt="location icon" />
                            <span>CC Pool</span>
                        </div>
                        <div className={style.link}>
                            <Link onClick={() => {
                                window.open(PrivacyAndPolicyLink, "_blanc")
                            }}>Privacy Policy</Link>
                            <p>Copyright &#169; Smoothy Factory 2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </MuiDialog>
    )
}
export default Dialog