import React from 'react'
import { TextTitle, CustomContainer } from '../../components'
import style from './rewards.module.css'
import data from './../../data/rewards.js'
import Reward from './Reward/Reward'


function Rewards() {
    const arr = data
    return (
        <section id='rewards' className={style.rewards} >
            <CustomContainer>
                <TextTitle text={"Earn Rewards"} />
                <div className={style.content}>
                    {arr.map((item, index) => {
                        return (
                            < Reward key={index} item={item} />
                        )
                    })}
                </div>
            </CustomContainer>
        </section>
    )
}

export default Rewards