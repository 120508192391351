import React, { useEffect, useRef } from 'react'
import { Link } from "react-scroll";
import { Button, Typography } from '@mui/material'

import style from './navbar.module.css'




function Navbar({ showMenu, matches, handleClickOpenDialog }) {

    const navbar = useRef(null)
    useEffect(() => {
        if (showMenu && matches) {
            navbar.current.style.height = '260px'
            navbar.current.style.visibility = 'visible'
            navbar.current.style.opacity = 1

        } else {
            navbar.current.style.height = 0
        }
    }, [showMenu, matches])
    return (
        <>
            <div className={style.navbar} ref={navbar}>
                <div className={style.menu} >
                    {navigationMenus.map((nav, i) => {
                        return (
                            < Link
                                key={i}
                                className={style.nav_link}
                                to={nav.path}
                                smooth={true}
                                duration={500}
                            > {nav.label}
                            </Link>
                        )
                    })}
                </div>
                <Button
                    variant="contained"
                    className={style.downloadBtn}
                    sx={{
                        backgroundColor: 'var(--btn-color)'
                    }}
                    onClick={handleClickOpenDialog}
                >
                    <Typography className={style.nav_btn}>download app now</Typography>
                </Button>

            </div>
        </>
    )
}

export default Navbar


export const navigationMenus = [
    {
        label: "Home",
        path: "home",
        AR: "الرئيسية",
    },
    {
        label: "About",
        path: "about-us",
        AR: "من نحن",
    },
    {
        label: "Rewards",
        path: "rewards",
        AR: "المكافآت",
    },
    {
        label: "Contact Us",
        path: "contact-us",
        AR: "تواصل معنا",
    },
];