import React from 'react'
import { Typography } from '@mui/material'
import { DownloadBtns } from '../../../components/index'
import style from './hometext.module.css'


function HomeText() {

    return (
        <div className={style.home_text}>
            <Typography
                className={style.title}
            >Healthier Side Of Life
            </Typography >
            <Typography >Download now and skip the line.</Typography >
            <DownloadBtns />
        </div>
    )
}

export default HomeText