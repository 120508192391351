import React from 'react'
import style from "./footer.module.css"
import logo from "../../assets/logo/logo.svg"
import locationIconPath from "../../assets/icons/location.svg"
import { Link } from 'react-router-dom'
import { CustomContainer as Container, DownloadBtns } from '../index'
const PrivacyAndPolicyLink = "https://www.privacypolicies.com/live/ec3dd02a-cd34-4cd2-9adc-4b742e729948"

function Footer() {
    return (
        <footer className={style.footer}>
            <Container>
                <div className={style.content}>
                    <div className={style.left_side}>
                        <img src={process.env.PUBLIC_URL + logo} alt="smoothie factory logo" />
                        <p>Conveniently pre-order your smoothie <br /> through our app at the following location:</p>
                        <div className={style.location}>
                            <img src={process.env.PUBLIC_URL + locationIconPath} alt="location icon" />
                            <span>BPC Gym</span>
                        </div>
                        <div className={style.location}>
                            <img src={process.env.PUBLIC_URL + locationIconPath} alt="location icon" />
                            <span>CC Pool</span>
                        </div>
                        {/* <div className={style.link}>
                            <Link onClick={() => {
                                window.open(PrivacyAndPolicyLink, "_blanc")
                            }}>Privacy Policy</Link>
                            <p style={{userSelect: 'none'}}>Copyright &#169; Smoothy Factory 2023</p>
                            <p >Powered By Nova Labs</p>
                        </div> */}
                    </div>
                    <DownloadBtns />
                </div>
                <div className={style.link}>
                    <Link onClick={() => {
                        window.open(PrivacyAndPolicyLink, "_blanc")
                        }}>Privacy Policy
                    </Link>
                    <p style={{userSelect: 'none'}}>Copyright &#169; Smoothy Factory 2023</p>
                    <p >Powered By Nova Labs</p>
                </div>
            </Container>
        </footer>
    )
}

export default Footer