import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Stack justifyContent={"center"} height="100vh" alignItems={"center"} >
            <Typography color={"primary"} variant="h1" fontWeight={"700"}>
                404
            </Typography>
            <Typography color={"primary"} variant="h2" fontWeight={"600"}>
                Page Not Found
            </Typography>
            <Button onClick={() => navigate(-1)} size="large" sx={{ my: 3, cursor: 'pointer' }}>
                Go Back
            </Button>
        </Stack>
    );
};

export default NotFound;
